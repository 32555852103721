import React from "react"
import i18nHOC from "../components/i18nHOC"
import Paragraph from "../components/Paragraph"
import ImageWithTitle from "../components/ImageWithTitle"
import { graphql } from "gatsby"
import ImageWithTitleFixed from "../components/ImageWithTitleFixed"
import Article from "../components/Article"

function nettle({ data, t }) {
  return <Article coverImage={{ image: data.coverImage, title: t("items.nettle.coverCap")}}
                  title={t("items.nettle.title")}>
    <Paragraph>{t("items.nettle.p1")}</Paragraph>
    <ImageWithTitle fluid={data.nettle1.childImageSharp.fluid}
                    title={t("items.nettle.cap1")}/>
    <ImageWithTitleFixed fixed={data.nettle2.childImageSharp.fixed}
                    title={t("items.nettle.cap2")}/>
    <ImageWithTitle fluid={data.nettle3.childImageSharp.fluid}
                    title={t("items.nettle.cap3")}/>
    <ImageWithTitle fluid={data.nettle4.childImageSharp.fluid}
                    title={t("items.nettle.cap4")}
                    style={{padding: '0 10%'}}/>
    <Paragraph>{t("items.nettle.p2")}</Paragraph>
    <ImageWithTitle fluid={data.nettle5.childImageSharp.fluid}
                    title={t("items.nettle.cap5")}/>
  </Article>
}

export default i18nHOC(nettle);

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "nettle/DSC_1679.JPG" }) {
      ...CoverImage
    },
    nettle1: file(relativePath: { eq: "nettle/cueillette-4 (1).jpg" }) {
      ...FluidArticleImage
    },
    nettle2: file(relativePath: { eq: "nettle/DSC_2538 (1).JPG" }) {
      ...PortraitFixedImage
    },
    nettle3: file(relativePath: { eq: "nettle/DSC_0029 (1).jpg" }) {
      ...FluidArticleImage
    },
    nettle4: file(relativePath: { eq: "nettle/DSC05522.jpg" }) {
      ...FluidArticleImage
    },
    nettle5: file(relativePath: { eq: "nettle/DSC08065 (3).JPG" }) {
      ...FluidArticleImage
    },
  }
`