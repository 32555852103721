import React from "react"
import Img from "gatsby-image"
import imageWithTitleCSS from "./ImageWithTitle.module.css"
import utilsCSS from "./utils.module.css"

export default function ImageWithTitleFixed({ fixed, title, style, titleStyle }) {
  let titleLines = Array.isArray(title) ? title : [title]
  return <div className={utilsCSS.centerWithFlex}>
    <div className={imageWithTitleCSS.imageWithTitle} style={style}>
      <Img fixed={fixed} alt="" title={title} loading="eager"/>
      <div className={imageWithTitleCSS.title} style={titleStyle}>
        {titleLines.map(line => <p>{line}</p>)}
      </div>
    </div>
  </div>
}

